import { DateTime, DurationUnits } from 'luxon'
import isPlainObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'

export const isServer = typeof window === 'undefined'

export function toFixed(value: number, fractionDigits: number): string {
  return value.toFixed(fractionDigits).replace(/\.?0+$/gi, '')
}

export function formatDate(
  value: Date | string,
  format: string,
  options?: { zone?: string }
): string {
  const date = new Date(value)
  return DateTime.fromJSDate(date, options).toFormat(format)
}

export function roundTime(date?: Date | string) {
  if (!date) return false
  const dateObj = typeof date === 'string' ? new Date(date) : date
  const roundedDate = new Date(dateObj)

  roundedDate.setHours(roundedDate.getHours() + Math.round(roundedDate.getMinutes() / 60))
  roundedDate.setMinutes(0, 0, 0) // Resets also seconds and milliseconds

  return roundedDate
}


type DateTimePreset = Partial<{
  year: 'numeric' | '2-digit'
  month: 'long' | 'short' | '2-digit' | 'numeric'
  day: 'numeric' | '2-digit'
  hour: 'numeric' | '2-digit'
  minute: 'numeric' | '2-digit'
  second: 'numeric' | '2-digit'
  timeZoneName: 'long' | 'short'
}>

const HH_MM_SS_PM: DateTimePreset = { hour: 'numeric', minute: 'numeric', second: 'numeric' }
const HH_MM_PM_TZ: DateTimePreset = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short'
}
const HH_MM: DateTimePreset = { hour: '2-digit', minute: '2-digit' }
const HH_MM_TZ: DateTimePreset = { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' }
const MONTH_DAY: DateTimePreset = { month: 'long', day: 'numeric' }
const MONTH_DAY_YEAR: DateTimePreset = { year: '2-digit', month: 'numeric', day: 'numeric' }

export const DATE_TIME_PRESETS = {
  TIME_MEDIUM: new Intl.DateTimeFormat('en-US', HH_MM_SS_PM),
  DATE_SHORT: new Intl.DateTimeFormat('en-US', MONTH_DAY),
  DATE_MEDIUM: new Intl.DateTimeFormat('en-US', MONTH_DAY_YEAR),
  TIME_LONG: new Intl.DateTimeFormat('en-US', HH_MM_PM_TZ),
  TIME_SHORT: new Intl.DateTimeFormat('en-US', HH_MM_TZ)
}

export function isDatePassed(dateISO: string) {
  return DateTime.fromISO(dateISO).diffNow().toMillis() < 0
}

export function getDateTimeInterval(
  targetDateTime: DateTime,
  currentDateTime = DateTime.local(),
  format: DurationUnits = ['days', 'hours', 'minutes', 'seconds']
) {
  return targetDateTime.diff(currentDateTime, format).toObject()
}

export function getRelativeTime(date: string | Date) {
  return DateTime.fromISO(date.toString()).toRelative()
}

export function flattenObject(
  _obj: Record<string, any>,
  _path: string[] = []
): Record<string, any> {
  const _flattenObject = (obj: Record<string, any>, path: string[]): Record<string, any> => {
    return !isPlainObject(obj)
      ? { [path.join('.')]: obj }
      : Object.entries(obj).reduce(
        (acc, [key, value]) => merge(acc, _flattenObject(value, [...path, key])),
        {}
      )
  }
  return _flattenObject(_obj, _path)
}

export async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

type NullishStrNum = string | number | null | undefined

export function parseNumber(defaultVal?: NullishStrNum) {
  return (val: string): NullishStrNum => {
    return val === '' ? defaultVal : val?.replace(/[^0-9.]/g, '')
  }
}

export function sanitizePhoneNumber(num: string) {
  return num?.replace(/[^0-9]/g, '')
}

export function isValidPhoneNumber(num: string) {
  const phoneRegex = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$', 'im')
  return phoneRegex.test(num)
}

export function getMuxThumbnailUrl(playbackId: string) {
  return `https://image.mux.com/${playbackId}/animated.gif`
}

export function kebabToSnake(str: string | undefined) {
  if (!str) {
    return undefined
  }
  return str.replaceAll('-', '_')
}

export function snakeToKebab(str: string | undefined) {
  if (!str) {
    return undefined
  }
  return str.replaceAll('_', '-')
}

export const formatCreditCardExp = (month: string, year: string) => {
  month = `${month}`.padStart(2, '0').slice(-2)
  year = `${year}`.slice(-2)
  return `${month}/${year}`
}

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (e) {
    console.error(e);
  }
}
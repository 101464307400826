import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import AvatarIcon from '@src/icons/avatar'
import { LoginModal } from './login-modal'
import { useUserAuth } from '@src/api/auth'
import { useEvent, useEvents } from '@src/api/event'
import { useCurrentLiveVideo } from '@src/api/story'
import { VOD_HERO_EVENT_ID } from '@src/lib/constants/events'

function AccountLink() {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const user = useUserAuth()
  const router = useRouter()

  function handleClick() {
    if (user) router.push('/account')
    else setIsOpen(true)
  }

  return (
    <div className='lg:w-56'>
      {isOpen && <LoginModal isOpen={isOpen} setIsOpen={setIsOpen} />}
      <button
        aria-label={user ? 'visit the account page' : 'open the sign in modal'}
        aria-haspopup={user ? undefined : 'true'}
        onClick={handleClick}
        className='flex items-center py-4 pr-4 ml-auto text-white'
      >
        <AvatarIcon color='none' height='26px' width='26px' />
      </button>
    </div>
  )
}

export default function Nav({
  type: overrideType
}: {
  type?: 'live' | 'on-demand' | 'ppv' | 'account'
}) {
  const router = useRouter()

  const isLive = router.pathname.startsWith('/live')
  const isVod = router.pathname.startsWith('/on-demand')
  const isPPV = router.pathname.startsWith('/ppv')

  const { data = [] } = useEvents({
    type: 'barstool_tv_ppv',
    sort: 'date',
    status: 'presale,upcoming,live,on_demand,ended'
  })

  const { data: liveVideos = [] } = useCurrentLiveVideo()
  const { data: liveEvent } = useEvent(VOD_HERO_EVENT_ID)

  const ppvExists = data?.length > 0
  const liveExists = false // hardcode for surviving barstool to avoid confusion
  // const liveExists = liveVideos?.length > 0 || (liveEvent && liveEvent?.status !== ARCHIVED)
  const displayOnDemand = liveExists || ppvExists

  const { type: _type, v } = router.query

  const type = overrideType ?? _type
  const nav = useRef<HTMLDivElement | null>(null)
  return (
    <>
      <div ref={nav}>
        <nav className='flex flex-col text-white bg-black md:hidden'>
          <div className='bg-[#1C1C1E]'>
            <div className='relative flex justify-between items-center h-[56px]'>
              <div className='flex items-center flex-shrink-0'>
                <img
                  className='block w-auto h-12'
                  src='/static/images/hero_logo.png'
                  alt='Barstool.TV'
                />
              </div>
              <AccountLink />
            </div>
          </div>
          {(liveExists || displayOnDemand || ppvExists) && (
            <div className='h-12 bg-[#1C1C1E]'>
              <div className='flex justify-start flex-1 h-full px-2 space-x-2 flex-nowrap'>
                {liveExists && (
                  <Link
                    href={v ? `/live?v=${v}` : '/live'}
                    className={classNames(
                      'flex items-center p-1 border-b-2 whitespace-nowrap',
                      isLive ? 'border-white text-white' : 'border-transparent text-gray-400'
                    )}
                  >
                    <div className='text-[9px] text-red-500 mr-1'> &#9679;</div> Watch Live
                  </Link>
                )}
                {displayOnDemand && (
                  <Link
                    href={v ? `/on-demand?v=${v}` : '/on-demand'}
                    className={classNames(
                      'flex items-center p-1 border-b-2 whitespace-nowrap',
                      isVod ? 'border-white text-white' : 'border-transparent text-gray-400'
                    )}
                  >
                    On Demand
                  </Link>
                )}
                {ppvExists && (
                  <Link
                    href={v ? `/ppv?v=${v}` : '/ppv'}
                    className={classNames(
                      'flex items-center p-1 border-b-2 whitespace-nowrap',
                      isPPV ? 'border-white text-white' : 'border-transparent text-gray-400'
                    )}
                  >
                    Pay-Per-View
                  </Link>
                )}
              </div>
            </div>
          )}
        </nav>
        <nav className='hidden md:flex flex-col md:flex-row md:w-full justify-start md:justify-between px-3 text-white bg-black md:bg-[#1C1C1E]'>
          <div className='flex-none mx-auto bg-[#1C1C1E]'>
            <div className='relative flex items-center justify-between h-[56px]'>
              <div className='flex items-center flex-1 md:items-stretch'>
                <Link href='/on-demand'>
                  <div className='flex items-center flex-shrink-0'>
                    <img
                      className='block w-auto h-12 lg:hidden'
                      src='/static/images/hero_logo.png'
                      alt='Barstool.TV'
                    />
                    <img
                      className='hidden object-contain w-auto h-12 max-h-full lg:block'
                      src='/static/images/hero_logo.png'
                      alt='Barstool.TV'
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className='flex justify-center flex-1 space-x-2 flex-nowrap'>
            {liveExists && (
              <Link
                href={v ? `/live?v=${v}` : '/live'}
                className={classNames(
                  'flex items-center p-1 border-b-2 whitespace-nowrap',
                  type === 'live' ? 'border-white text-white' : 'border-transparent text-gray-400'
                )}
              >
                <div className='text-[9px] text-red-500 mr-1'> &#9679;</div> Watch Live
              </Link>
            )}
            {displayOnDemand && (
              <Link
                href={v ? `/on-demand?v=${v}` : '/on-demand'}
                scroll={false}
                className={classNames(
                  'flex items-center p-1 border-b-2 whitespace-nowrap',
                  type === 'on-demand'
                    ? 'border-white text-white'
                    : 'border-transparent text-gray-400'
                )}
              >
                On Demand
              </Link>
            )}
            {ppvExists && (
              <Link
                href={v ? `/ppv?v=${v}` : '/ppv'}
                scroll={false}
                className={classNames(
                  'flex items-center p-1 border-b-2 whitespace-nowrap',
                  type === 'ppv' ? 'border-white text-white' : 'border-transparent text-gray-400'
                )}
              >
                Pay-Per-View
              </Link>
            )}
          </div>
          <AccountLink />
        </nav>
      </div>
    </>
  )
}

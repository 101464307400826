import { SWRConfiguration } from 'swr'
import { Service, useGet, useGetSafe, useRead } from './common'
import { playlistApi } from './playlist'
import { ListParams, ReadParams, Story, StoryVideoSource } from './types'
import { unionApiHost } from '@src/lib/env'

class StoryApi extends Service<Story> {
  constructor() {
    super('/stories', unionApiHost)
  }
  async find(params: ListParams<any>) {
    const result = await this.get<Story[]>('/stories/latest', { ...params })
    return result
  }
  async findPlaylistById(id: string) {
    const result = await this.get(`/playlists/${id}`)
    return result
  }
  async videoSource(id: string) {
    if (!id) return null
    const result = await this.get<StoryVideoSource>(
      `/stories/${id}/video-source`
      // { parse_manifest: true }
    )
    return result
  }

  async getLatestStories(params?: ListParams<any>) {
    const result = await this.get<Story[]>('/stories/latest', { ...params })
    return result
  }

  async findViewerCount(id: string) {
    const result = await this.get<{ count?: number }>(`/stories/id/${id}/presence`)
    return result.count || 1
  }
  async postViewerCount(id: string, data: Record<string, any>) {
    const result = await this.post<{ count?: number }>(`/stories/id/${id}/presence`, data)
    return result.count || 1
  }

  async findFeeds(items: FeedRequestItem[]) {
    const promises = items.map(_findPlaylistOrBrandFeed)
    const feeds = await Promise.all(promises)
    return feeds
  }

  async findLive(params?: ListParams<any>) {
    const result = await this.get<Story[]>(`/stories/live`, params)
    return result
  }
}

type FeedRequestItem = { type: 'show' | 'playlist'; id: string }

const _findPlaylistOrBrandFeed = async (item: FeedRequestItem) => {
  if (item.type === 'show') {
    return storyApi.find({ limit: 50, brand: item.id, type: 'barstool_original' })
  } else {
    return playlistApi.read(item.id, { limit: 50 })
  }
}

export const storyApi = new StoryApi()

export function useVideos(
  params?: ListParams<Story>,
  shouldFetch?: () => boolean,
  config?: SWRConfiguration
) {
  return useGetSafe<Story[]>(
    storyApi,
    '/stories/latest',
    { limit: 7, type: 'barstool_original', ...params },
    shouldFetch,
    config
  )
}

export function useVideoSource(
  id: string | undefined,
  params?: ReadParams,
  config?: SWRConfiguration
) {
  return useGet<StoryVideoSource>(
    storyApi,
    id ? `/stories/${id}/video-source` : null,
    params,
    config
  )
}

export function useStory(id: string, params?: ReadParams, config?: SWRConfiguration) {
  return useRead(storyApi, id, params, config)
}

export function useCurrentLiveVideo(shouldFetch?: () => boolean, config?: SWRConfiguration) {
  return useGetSafe<Story[]>(
    storyApi,
    '/stories/live',
    { sort: 'viewers', limit: 5, ignore_time: true },
    shouldFetch,
    config
  )
}

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form, useFormState, useForm } from 'react-final-form'
import { Modal } from './ui/modal'
import isEmail from 'validator/lib/isEmail'
import { authApi } from '@src/api/auth'
import { ApiError } from '@src/api/utils'
import TextField from './ui/text-field'
import { useRouter } from 'next/router'
import { FormNotification } from './ui/form-notification'
import Button from './ui/button'
import { Event } from '@src/api/types'
import { track } from '@lib/whoami'

function EmailBody({
  forgotPassword = false,
  isLogin,
  isSignUp
}: {
  forgotPassword?: boolean
  isLogin?: boolean
  isSignUp?: boolean
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const { change, reset } = useForm()
  const { values } = useFormState()

  async function handleClick(e: any) {
    e.preventDefault()
    setIsLoading(true)

    const { email } = values

    try {
      if (!email || !isEmail(email)) throw 'Enter a valid email address'

      if (!forgotPassword) {
        const { doesExist }: any = await authApi.emailCheck(email)
        change('form_type', doesExist ? 'PASSWORD' : 'NEW_ACCOUNT')
      } else {
        await authApi.forgotPassword(email)
        setSuccess('A password reset link has been sent')
      }
    } catch (e) {
      if (e instanceof ApiError) setError(e?.message ?? e)
      if (typeof e === 'string') setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  function handleSwitchFlow() {
    change('form_type', isLogin ? 'SIGN_UP' : 'LOGIN')
  }

  return (
    <>
      {!isSignUp && !forgotPassword && (
        <div className='mb-6 text-sm'>
          {values.purchaseFlow ? (
            <>
              <h3 className='mb-1 font-medium uppercase'>Step 1 of 3</h3>
              <p className='leading-none'>Provide your email address below.</p>
            </>
          ) : (
            <>
              <p>
                Enter your email address. If you have a Barstool Sports account, you may use it to
                access Barstool.tv.
              </p>
            </>
          )}
        </div>
      )}
      {error && <FormNotification error message={error} />}
      {success && <FormNotification success message={success} />}
      <TextField
        className='mb-4'
        name={'email'}
        type='email'
        placeholder='Email'
        autoComplete='username'
      />
      <Button
        primary
        disabled={!values.email?.length}
        loading={isLoading}
        onClick={handleClick}
        className='mb-4'
      >
        {forgotPassword ? 'Submit' : 'Continue'}
      </Button>
      {forgotPassword ? (
        <button
          onClick={(e) => {
            e.preventDefault()
            setSuccess(null)
            reset()
          }}
          className='w-full text-center text-xs text-[#DEDEDE] underline'
        >
          Return to sign in
        </button>
      ) : (
        <div className='w-full text-sm text-[#B3B3B3]'>
          By submitting this form I agree to this site&apos;s{' '}
          <a
            className='text-white underline'
            target='_blank'
            href='https://www.barstoolsports.com/terms-of-use'
            rel='noreferrer'
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            className='text-white underline'
            target='_blank'
            href='https://www.barstoolsports.com/privacy-policy'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
          .
        </div>
      )}
      {(isLogin || isSignUp) && (
        <div className='mt-4'>
          <p className='mb-4 text-sm font-medium text-center text-white '>
            {isLogin ? "Don't have an account?" : 'Have an account?'}
          </p>
          <Button
            className='bg-transparent border-2 border-white border-solid'
            onClick={handleSwitchFlow}
            type='button'
          >
            {isLogin ? 'Join Now' : 'Log In'}
          </Button>
        </div>
      )}
    </>
  )
}

function PasswordBody({
  newAccount = false,
  resetPassword = false
}: {
  newAccount?: boolean
  resetPassword?: boolean
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const { reset, change } = useForm()
  const { values } = useFormState()

  const email = values?.email

  async function handleClick(e: any) {
    e.preventDefault()
    setIsLoading(true)

    const { password } = values
    try {
      if (!password) throw 'Password required'
      if (password.length < 4) throw 'Passwords must contain 4 or more characters'

      if (!newAccount && !resetPassword) {
        const { user } = await authApi.login({ email: values.email, password: values.password })
        track('AccountLogin', {
          user: {
            barstoolUserID: user.id,
            email: user.email
          }
        })
        change('form_type', 'SUCCESS')
      } else if (newAccount) {
        const { user } = await authApi.signup({ email: values.email, password: values.password })
        track('AccountCreated', {
          user: {
            barstoolUserID: user.id,
            email: user.email
          }
        })
        track('AccountLogin', {
          user: {
            barstoolUserID: user.id,
            email: user.email
          }
        })
        change('form_type', 'SUCCESS')
      }
    } catch (e) {
      if (e instanceof ApiError) setError(e?.message ?? e)
      if (typeof e === 'string') setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {values.purchaseFlow && (
        <div className='mb-6 text-sm'>
          <h3 className='mb-1 font-medium uppercase'>Step 2 of 3</h3>
          <p className='leading-none'>{newAccount ? 'Set a password.' : 'Enter your password.'}</p>
        </div>
      )}
      {error && <FormNotification error message={error} />}
      {email && <TextField className='mb-3' name='email' placeholder='Email Address' disabled />}
      <TextField
        className='mb-6'
        type='password'
        name='password'
        placeholder='Password'
        autoComplete='current-password'
      />
      <div className='flex flex-col gap-6'>
        <Button
          primary
          onClick={handleClick}
          disabled={!values.password?.length}
          loading={isLoading}
        >
          {resetPassword ? 'Submit' : 'Continue'}
        </Button>
        {!newAccount && (
          <button
            onClick={(e) => {
              e.preventDefault()

              if (resetPassword) {
                reset()
              } else {
                change('form_type', 'FORGOT_PASSWORD')
              }
            }}
            className='w-full text-sm text-center underline'
          >
            {resetPassword ? 'Return to sign in' : 'Forgot Password'}
          </button>
        )}
        <button
          onClick={(e) => {
            e.preventDefault()
            reset()
          }}
          className='w-full text-sm font-bold text-center text-white'
        >
          Back
        </button>
      </div>
    </>
  )
}

function SuccessBody() {
  const { submit } = useForm()
  const { values } = useFormState()

  useEffect(() => {
    setTimeout(() => {
      submit()
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='flex flex-col items-center mt-16'>
      <img
        src='/static/images/stool-and-stars-white.svg'
        alt='stool-and-stars-success'
        className='mb-6 w-28 h-28'
      />
      <FormNotification success message={values.successMessage} />
    </div>
  )
}

function LoginBody({
  isLogin,
  isSignUp
}: {
  forgotPassword?: boolean
  isLogin?: boolean
  isSignUp?: boolean
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const { change } = useForm()
  const { values } = useFormState()

  const isPurchaseFlow = values?.purchaseFlow

  async function handleClick(e: any) {
    e.preventDefault()
    setIsLoading(true)
    setError(null)

    const { email, password } = values

    try {
      if (!email || !isEmail(email)) throw 'Enter a valid email address'
      if (!password) throw 'Password required'
      if (password.length < 4) throw 'Passwords must contain 4 or more characters'

      if (isLogin) {
        const { user } = await authApi.login({ email: values.email, password: values.password })
        track('AccountLogin', {
          user: {
            barstoolUserID: user.id,
            email: user.email
          }
        })
        change('form_type', 'SUCCESS')
      } else if (isSignUp) {
        const { user } = await authApi.signup({ email: values.email, password: values.password })
        track('AccountCreated', {
          user: {
            barstoolUserID: user.id,
            email: user.email
          }
        })
        track('AccountLogin', {
          user: {
            barstoolUserID: user.id,
            email: user.email
          }
        })
        change('form_type', 'SUCCESS')
      }
    } catch (e) {
      if (e instanceof ApiError) {
        const errorMessage =
          e?.message === 'Your password was incorrect.' ||
          e?.message === 'Could not find an account with that email.'
            ? 'Could not find an account with that information'
            : e?.message ?? e
        setError(errorMessage)
      }
      if (typeof e === 'string') setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  function handleSwitchFlow() {
    change('form_type', isLogin ? 'SIGN_UP' : 'LOGIN')
    setError(null)
  }

  return (
    <>
      {(isPurchaseFlow || isLogin) && (
        <div className='mb-6 text-sm'>
          {isPurchaseFlow ? (
            <>
              <h3 className='mb-1 font-medium uppercase'>Step 1 of 2</h3>
              <p className='leading-none'>Provide your email address below.</p>
            </>
          ) : (
            <>
              <p>
                Enter your email address. If you have a Barstool Sports account, you may use it to
                access Barstool.tv.
              </p>
            </>
          )}
        </div>
      )}
      {error && <FormNotification error message={error} />}
      <TextField
        className='mb-4'
        name={'email'}
        type='email'
        placeholder='Email'
        autoComplete='username'
      />
      <TextField
        className='mb-6'
        type='password'
        name='password'
        placeholder='Password'
        autoComplete='current-password'
      />
      <Button
        primary
        disabled={!values.email?.length}
        loading={isLoading}
        onClick={handleClick}
        className='mb-4'
      >
        {isLogin ? 'Submit' : 'Create Account'}
      </Button>
      {isLogin && (
        <button
          onClick={(e) => {
            e.preventDefault()
            change('form_type', 'FORGOT_PASSWORD')
          }}
          className='w-full mb-4 text-sm text-center underline'
        >
          Forgot Password
        </button>
      )}
      {isSignUp && (
        <div className='w-full text-sm text-[#B3B3B3]'>
          By submitting this form I agree to this site&apos;s{' '}
          <a
            className='text-white underline'
            target='_blank'
            href='https://www.barstoolsports.com/terms-of-use'
            rel='noreferrer'
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            className='text-white underline'
            target='_blank'
            href='https://www.barstoolsports.com/privacy-policy'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
          .
        </div>
      )}
      <div className='mt-4'>
        <p className='mb-4 text-sm font-medium text-center text-white '>
          {isLogin ? "Don't have an account?" : 'Have an account?'}
        </p>
        <Button
          className='bg-transparent border-2 border-white border-solid'
          onClick={handleSwitchFlow}
          type='button'
        >
          {isLogin ? 'Join Now' : 'Log In'}
        </Button>
      </div>
    </>
  )
}

const INIT_VALUES = {
  email: '',
  password: '',
  confirm_password: '',
  form_type: 'LOGIN'
}

const FORM_OPTIONS: {
  [key: string]: {
    title?: string
    subtitle?: string
    body: JSX.Element
    hideCloseIcon?: boolean
  }
} = {
  LOGIN: {
    title: 'Login',
    body: <LoginBody isLogin />
  },
  SIGN_UP: {
    title: 'Join Now',
    subtitle: 'Provide your email to create an account.',
    body: <LoginBody isSignUp />
  },
  PASSWORD: {
    title: 'It looks like you already have a Barstool Sports account.',
    body: <PasswordBody />
  },
  FORGOT_PASSWORD: {
    title: 'Forgot your password.',
    subtitle: 'Please enter your email address.',
    body: <EmailBody forgotPassword />
  },
  RESET_PASSWORD: {
    title: 'Create your new password',
    subtitle: 'Create your new password',
    body: <PasswordBody resetPassword />
  },
  SUCCESS: {
    body: <SuccessBody />,
    hideCloseIcon: true
  }
}

export function LoginModal({
  event,
  isOpen,
  setIsOpen,
  onSuccess,
  successMessage = 'Success!'
}: {
  event?: Event
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onSuccess?: () => void
  successMessage?: string
}) {
  const router = useRouter()

  function handleSuccess() {
    if (onSuccess) onSuccess()
    else router.reload()
  }

  return (
    <Form
      initialValues={{ ...INIT_VALUES, purchaseFlow: !!event, successMessage }}
      onSubmit={handleSuccess}
      render={({ values, form }) => {
        const { title, subtitle, body, hideCloseIcon = false } = FORM_OPTIONS[values?.form_type]

        function resetFormValues() {
          form.reset()
        }

        const modalTitle =
          values.form_type === 'LOGIN'
            ? event
              ? `Access ${event.name} in just two steps.`
              : 'Login'
            : title

        return (
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClose={resetFormValues}
            title={modalTitle}
            subtitle={subtitle}
            hideCloseIcon={hideCloseIcon}
          >
            <form>{body}</form>
          </Modal>
        )
      }}
    />
  )
}
